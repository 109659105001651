import React from 'react'; 
import gongan from '../images/jgawb.png';
import rcode from '../images/xcx.png';
import phoneBottom from '../images/phoneBottom.png'
import wxBottom from '../images/wxBottom.png'
import './Footer.css';


// let popupDisplay='popupNone'
// function popupStateF(event){
//     if(popupDisplay=='popupNone'){
//         popupDisplay = 'popupBlock'
//     }else{
//         popupDisplay = 'popupNone'
//     }
//     console.log(7777)
// }

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            footer_popupDisplay:'footer_popupNone'
        };
    }

    foot_popupStateF(){
        if(this.state.footer_popupDisplay=='footer_popupNone'){
            this.setState({
                footer_popupDisplay:'footer_popupBlock'
            })
        }else{
            this.setState({
                footer_popupDisplay:'footer_popupNone'
            })
        }
    }

    cancel(){
        this.setState({
            footer_popupDisplay:'footer_popupNone'
        })
    }

    componentDidMount(){
    }

    render(){
        return (
            <div className="footer">
                <div className="footer-container displayNonePC">
                    <div className="left">
                        <div className="name">上海心盒科技有限公司</div>
                        <div className="Enname">Shanghai Xinhe Technology Co., LTD</div>
                        <div className="infobox">
                            <p>网址：www.xinhekeji.net</p>
                            <p>商务咨询：19117353698 夏先生</p>
                            <p>公司邮箱：info@xinhekeji.net</p>
                            <p>地址：上海市静安区南京西路580号仲益大厦B座609</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="tip">关注小程序了解更多惊喜~~</div>
                        <img src={rcode} alt="" className="rcode" />
                        <div className="laizi">
                            <img src={gongan} alt="" className="icon" />
                            <a href='https://beian.miit.gov.cn/' style={{color: 'white'}}>沪ICP备2021001714号-1</a>
                        </div>
                    </div>
                </div>

                <div className='displayNonePhone'>
                    <div className='home_items'>
                        <div className='home_item_list'>
                            <img src={phoneBottom} alt="" className="phoneBottom" />
                            <span>商务咨询：19117353698</span>
                        </div>
                        <div className='home_item_list'>
                            <img src={wxBottom} alt="" className="wxBottom" />
                            <span>关注心愿盒Match box</span>
                        </div>
                    </div>
                    <div className='foot_main'>
                        <div className="infobox">
                            <div>网址：www.xinhekeji.net</div>
                            <div>公司邮箱：info@xinhekeji.net</div>
                            <div>地址：上海市静安区南京西路580号仲益大厦B座609</div>
                            <div>
                                <a href='https://beian.miit.gov.cn/'>上海心盒科技有限公司 沪ICP备2021001714号-1</a>
                            </div>
                        </div>
                        <img src={rcode} alt="" className="rcode" />
                    </div>
                    <div className='btm-btn-contain displayNonePhone'>
                        <a className='call' href="tel:19117353698"><div className='btm-btn' onClick={this.foot_popupStateF.bind(this)}>立即咨询</div></a>
                    </div>

                </div>

                {/*<div className={this.state.footer_popupDisplay=='footer_popupBlock'?'footer_popupBlock phone-contain':'footer_popupNone phone-contain'}>*/}
                {/*    <a className='call' href="tel:18818216268">*/}
                {/*        <img src={phoneBottom}/>*/}
                {/*        <span>呼叫</span>*/}
                {/*        <span>18818216268</span>*/}
                {/*    </a>*/}
                {/*    <div className='cancel' onClick={this.cancel.bind(this)}>取消</div>*/}
                {/*</div>*/}
                {/*<div className={this.state.footer_popupDisplay=='footer_popupBlock'?'footer_popupBlock foot_popup':'footer_popupNone foot_popup'} onClick={this.foot_popupStateF.bind(this)}></div>*/}
            </div>
        )
    }
}

Footer.propTypes = {

}

export default Footer; 
