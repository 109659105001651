import React from 'react'
import './Example.css';
import HomeIcon from "../images/home.png";
import Img1 from "../images/example_img_1.png";
import Img2 from "../images/example_img_2.png";
import Img3 from "../images/example_img_3.png";
import Img4 from "../images/example_img_4.png";
import Img5 from "../images/example_img_5.png";
import Img6 from "../images/example_img_6.png";
import Icon1 from "../images/example_icon_1.png";
import Icon2 from "../images/example_icon_2.png";
import Icon3 from "../images/example_icon_3.png";
import Icon4 from "../images/example_icon_4.png";
import Icon5 from "../images/example_icon_5.png";
import Icon6 from "../images/example_icon_6.png";
import {Link} from "react-router-dom";


class Example extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            itemStyle:'example_1F',
            getImgs:[],
            getPhoneImgs:[],
            banner:'',
            banner2:''
        };
    }

    setHref(id) {
        this.setState({
            itemStyle:id
        })
        document.documentElement.scrollTop = document.getElementById(id).offsetTop-
        document.getElementsByClassName('example-nav')[0].offsetHeight -
        document.getElementsByClassName('navbar-container')[0].offsetHeight
    }


    move = () => {
        if (this.oUl.offsetLeft < -this.oUl.offsetWidth / this.speed) {
        this.oUl.style.left = '0'
        }
        if (this.oUl.offsetLeft > 0) {
        this.oUl.style.left = -this.oUl.offsetWidth / this.speed + 'px';
        }
        this.oUl.style.left = this.oUl.offsetLeft - 2 + 'px';
    }

    handleImageScroll() {

        this.oUl = document.getElementsByClassName('image-container')[0].getElementsByTagName('ul')[0];
        this.oLis = document.getElementsByClassName('image-container')[0].getElementsByTagName('li');
        this.oUl.innerHTML = this.oUl.innerHTML + this.oUl.innerHTML;
        this.oUl.style.width = this.oLis[0].offsetWidth * this.oLis.length + 'px';
        this.speed = 2;
        this.interval = setInterval(this.move, 30);

    }

    startImageScroll = () => {

        this.interval = setInterval(this.move, 30);

    }

    endImageScroll = () => {
        clearInterval(this.interval);
    }


    importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item).default; });
        return images;
    }
    goHome(){
        window.location.reload()
    }
    reload(){
        setTimeout(()=>{
            window.location.reload()
        },10)
    }
    // link(number,event){
    //     console.log(this)
    //     this.push("/Detail/"+number)
    //     // this.props.history.push("/Detail/"+number)
    //     console.log(number)
    //     console.log(event)
    // }
    componentDidMount(){
        // console.log(this.importAll)
        const images = this.importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));
        let getImgs = []
        let getPhoneImgs = []
        let banner = images['banner_example.jpeg']
        let banner2 = images['banner_example2.png']
        let partner_banner = images['partner_banner.png']
        for(let i=1;i<=18;i++){
            getImgs.push(images[i+'.png'])
            getPhoneImgs.push(images['phone'+i+'.png'])
        }
        this.setState({
            getImgs:getImgs,
            getPhoneImgs:getPhoneImgs,
            banner:banner,
            banner2:banner2, 
            partner_banner:partner_banner
        }, () => {
            this.handleImageScroll(); 
        })

    }
    componentWillUnmount() {
        clearInterval(this.interval); 
    }

    render(){
        return(
           <div>
               <div className="banner">
                   <img className="banner-background displayNoneBlockPC" src={this.state.banner} />
                   <img className="banner-background displayNonePhone" src={this.state.banner2} />
                   <div className="example-banner-subtitle-en">
                       Information center
                   </div>
                   <div className="example-banner-subtitle-cn">
                       客户案例
                   </div>
               </div>
               <div className='example-nav displayNoneBlockPC'>
                   <div className='nav-container'>
                       <div className='left'>
                           <img src={HomeIcon}/>
                           <span onClick={this.goHome}><Link to='/'>首页</Link></span>
                           <span>&gt;</span>
                           <span>客户案例</span>
                       </div>
                       <div className='right'>
                           <span className={this.state.itemStyle=='example_1F'?'active':''} onClick={this.setHref.bind(this,'example_1F')}>客户案例</span>
                           <span className={this.state.itemStyle=='example_2F'?'active':''} onClick={this.setHref.bind(this,'example_2F')}>业务体系</span>
                           <span className={this.state.itemStyle=='example_3F'?'active':''} onClick={this.setHref.bind(this,'example_3F')}>合作伙伴</span>
                       </div>
                   </div>
               </div>

               <div className="example-container">
                   <div className="sec-title" id="example_1F">
                       <div className="text"> 客户案例</div>
                       <div className='line displayNoneBlockPC'>
                           <span></span>
                           <span></span>
                           <span></span>
                       </div>
                   </div>

                   <div className="sec-title">
                       <div className='product-list'>
                           <img src={Img1} className='displayNoneBlockPC'/>
                           <Link to='/Detail/detail_1'>
                               <img src={Img1} className='displayNonePhone'/>
                           </Link>
                           <div className='name'>小锅万物</div>
                           <div className='product-list-popup'>
                               <div className='product-list-popup_bg displayNoneBlockPC'></div>
                               <div className='content displayNoneBlockPC'>
                                   <div className='title'>服务方案:</div>
                                   <div className='substr'>随机人群测试，定位核心人群；科学合理的问卷预设数据分析帮助品牌方发掘产品问题及优化方向</div>
                                   <div className='title'>关键产出:</div>
                                   <ul className='substr'>
                                       <li>基于超过80%的调研反馈率获取的大量体验数据</li>
                                       <li>全方位的产品优化建议</li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                       <div className='product-list'>
                           <img src={Img2} className='displayNoneBlockPC'/>
                           <Link to='/Detail/detail_2'>
                               <img src={Img2} className='displayNonePhone'/>
                           </Link>
                           <div className='name'>醒肌密</div>
                           <div className='product-list-popup'>
                               <div className='product-list-popup_bg displayNoneBlockPC'></div>
                               <div className='content displayNoneBlockPC'>
                                   <div className='title'>服务方案:</div>
                                   <div className='substr'>基于同品类派送经验给出人群优化建议，精准派发核心用户，分析核心用户特征并挖掘业务增长点</div>
                                   <div className='title'>关键产出:</div>
                                   <ul className='substr'>
                                       <li>超高的私域引流沉淀率</li>
                                       <li>男性用户增长点，并获得公域验证</li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                       <div className='product-list'>
                           <img src={Img3} className='displayNoneBlockPC'/>
                           <Link to='/Detail/detail_3'>
                               <img src={Img3} className='displayNonePhone'/>
                           </Link>
                           <div className='name'>金活医药</div>
                           <div className='product-list-popup'>
                               <div className='product-list-popup_bg displayNoneBlockPC'></div>
                               <div className='content displayNoneBlockPC'>
                                   <div className='title'>服务方案:</div>
                                   <div className='substr'>销售TA及可能性TA画像相结合，采取正相关匹配定点派送的形式进行样品派发；分析反馈数据帮助品牌发现成熟产品更多的营销可能性</div>
                                   <div className='title'>关键产出:</div>
                                   <ul className='substr'>
                                       <li>售卖产品规格优化建议，取得优秀转化</li>
                                       <li>市场方向及C端营销角度建议</li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                       <div className='product-list'>
                           <img src={Img4} className='displayNoneBlockPC'/>
                           <Link to='/Detail/detail_4'>
                               <img src={Img4} className='displayNonePhone'/>
                           </Link>
                           <div className='name'>21cake</div>
                           <div className='product-list-popup'>
                               <div className='product-list-popup_bg displayNoneBlockPC'></div>
                               <div className='content displayNoneBlockPC'>
                                   <div className='title'>服务方案:</div>
                                   <div className='substr'>根据新品特点，定制问卷的形式来聚焦新品受众，通过产品派样测试的形式收集反馈进行数据分析，并且对人群进行分析，明确核心受众特征</div>
                                   <div className='title'>关键产出:</div>
                                   <ul className='substr'>
                                       <li>1:1的核心用户私域沉淀</li>
                                       <li>产品的口碑传播及多方面优化建议</li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                       <div className='product-list'>
                           <img src={Img5} className='displayNoneBlockPC'/>
                           <Link to='/Detail/detail_5'>
                               <img src={Img5} className='displayNonePhone'/>
                           </Link>
                           <div className='name'>偶爱你</div>
                           <div className='product-list-popup'>
                               <div className='product-list-popup_bg displayNoneBlockPC'></div>
                               <div className='content displayNoneBlockPC'>
                                   <div className='title'>服务方案:</div>
                                   <div className='substr'>通过样品派送体验的形式给到用户进行试用，培养用户习惯。并分析好评人群特征，进一步聚焦核心用户</div>
                                   <div className='title'>关键产出:</div>
                                   <ul className='substr'>
                                       <li>产品销售量显著提升</li>
                                       <li>核心人群画像以及营销角度建议</li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                       <div className='product-list'>
                           <img src={Img6} className='displayNoneBlockPC'/>
                           <Link to='/Detail/detail_6'>
                               <img src={Img6} className='displayNonePhone'/>
                           </Link>
                           <div className='name'>ElectroX</div>
                           <div className='product-list-popup'>
                               <div className='product-list-popup_bg displayNoneBlockPC'></div>
                               <div className='content displayNoneBlockPC'>
                                   <div className='title'>服务方案:</div>
                                   <div className='substr'>根据人群画像进行相关性匹配派送，结合新品期待预设问题和消费者共创产品，并且收集用户反馈分析核心人群的特征和定位</div>
                                   <div className='title'>关键产出:</div>
                                   <ul className='substr'>
                                       <li>新品研发方向</li>
                                       <li>线下销售推广思路</li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="sec-title" id="example_2F">
                       <div className="text"> 业务体系</div>
                       <div className='line displayNoneBlockPC'>
                           <span></span>
                           <span></span>
                           <span></span>
                       </div>
                   </div>

                   <div className='sec-title'>
                       <div className='business'>
                           <img src={Icon1}/>
                           <div className='content'>
                               <div>基础派送</div>
                               <div>基于精准标签匹配的货找人体验营销，和消费者一起共创您的产品</div>
                           </div>
                       </div>
                       <div className='business'>
                           <img src={Icon2}/>
                           <div className='content'>
                               <div>深度调研</div>
                               <div>多维度、全方位的产品深度调研服务，帮助您更了解您的产品</div>
                           </div>
                       </div>
                       <div className='business'>
                           <img src={Icon3}/>
                           <div className='content'>
                               <div>精准引流</div>
                               <div>基于精准用户标签匹配的私域引流机制，优质的私域流量才能带来安全感</div>
                           </div>
                       </div>
                       <div className='business'>
                           <img src={Icon4}/>
                           <div className='content'>
                               <div>心盒共创CEM</div>
                               <div>产品体验营销全流程管理的SaaS工具，一体化地了解消费者对产品的真实反馈</div>
                           </div>
                       </div>
                       <div className='business'>
                           <img src={Icon5}/>
                           <div className='content'>
                               <div>品牌曝光</div>
                               <div>10w+超一线城市高品质用户的产品曝光，帮助您的品牌迅速建立潮流人群心智</div>
                           </div>
                       </div>
                       <div className='business'>
                           <img src={Icon6}/>
                           <div className='content'>
                               <div>咨询服务</div>
                               <div>来自贝恩、Transcosmos等知名咨询公司的行业专家咨询，专业且真诚的服务</div>
                           </div>
                       </div>
                   </div>

                   <div className="btn">
                       <Link to='/ContactUs' onClick={this.reload}>了解更多</Link>
                   </div>

                   <div className="sec-title" id="example_3F">
                       <div className="text"> 合作伙伴</div>
                       <div className='line displayNoneBlockPC'>
                           <span></span>
                           <span></span>
                           <span></span>
                       </div>
                   </div>

                   <div className="cloud-container">
                        <div className="image-container" onMouseOver={this.endImageScroll} onMouseOut={this.startImageScroll}>

                        <ul>
                            <li>
                            <img src={this.state.partner_banner} alt="" />
                            </li>
                            <li>
                            <img src={this.state.partner_banner} alt="" />
                            </li>
                            <li>
                            <img src={this.state.partner_banner} alt="" />
                            </li>
                        </ul>
                        </div>
                    </div>

                   {/* <ul className="partner">
                       {
                           this.state.getPhoneImgs.map((item,index)=>{
                               return <li key={'img'+index}><img src={item} /></li>
                           })
                       }
                   </ul> */}


               </div>
           </div>
        )
    }
}

Example.propTypes = {}

export default Example
// function Example() {
//     return (
//         <div>
//
//         </div>
//     )
// }
//
// export default Example
