
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';  
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Example from './pages/Example'; 
import ContactUs from './pages/ContactUs'; 
import AboutUs from './pages/AboutUs'; 
import Footer from './components/Footer';
import Detail from './components/Detail';


function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Example" element={<Example /> } />
          <Route path="/AboutUs"  element={<AboutUs /> } />
          <Route path="/ContactUs"  element={<ContactUs /> }  />
          <Route path="/Detail/:id"  element={<Detail /> }  />
        </Routes>
        <Footer /> 
      </Router>
      
    </> 
  );
}

export default App;
